export const environment = {
  apiUrl: 'https://api.base.cliv.info',
  ENV: "prod",
  sentry: {"enable":true,"enableBrowserTracingIntegration":true,"enableReplayIntegration":false},
  dataName: 'soft',
  clientName: 'try',
  enableReset: true,
  production: true,
  uiClienId: 'a2cb7ab5-5b7a-4d43-8dcb-c364369697a8',
  redirectUrl: 'https://try.cliv.info',
  navBar: {
    leftImage: 'assets/svg/logo-dark.svg',
    leftImageHeight: 30,
    menuSetting: '[{"name":"home","default":true,"report-id":"1b414d40-fef0-4e72-914a-9827293011de","page-name":"ReportSection578191c5ceaa5ac0fec3"},{"name":"management report","default":false,"report-id":"1b414d40-fef0-4e72-914a-9827293011de","page-name":"ReportSection08cd1c51b0ba529b769c"},{"name":"inspection","default":false},{"name":"request inspection","default":false}]',
    roleFilter: '"{}"',
    showAdmin: true,
    asDropdown: false,
  },
  sideBar: '{"po-selection":{"show":false},"packaging":{"show":false},"downloads":{"show":false},"inspection-plan":{"title":"AQL Inspection Plan"},"inspections":{"icon":"t-shirt.svg"}}',
  import: '{"select-centre":false,"select-group":false,"select-inspection-type":true,"select-inspection-level":true,"title":"UPLOAD PRODUCT READY TO INSPECT"}',
  uploadSizeChart: '{"title":"PRODUCT DETAILS","product-code-title":"Style Code","add-title":"ADD PRODUCT","delete-title":"DELETE PRODUCT","need-upload":true}',
  clientInspection: '{"date-range-minus":10,"result-filter":"Result","consol-filter":"Consol. Centre","division-filter":"","colour-filter":"Colour","department-list":"","po-number":"PO Number","table":[{"name":"Insp. Date","key":"inspection_date","case":"name"},{"name":"Loc.","type":"number","key":"UDC_id","case":"normal"},{"name":"Insp.","type":"number","key":"inspection_number","case":"normal"},{"name":"Supplier Name","key":"supplier_name","case":"name"},{"name":"Season","key":"season_code","case":"normal"},{"name":"PO Numbers","key":"client_pos","case":"normal"},{"name":"Style","key":"style_code","case":"normal"},{"name":"Description","key":"style_name","case":"name"},{"name":"Colour","key":"colour","case":"name"},{"name":"Result","key":"result","case":"result"},{"name":"Full Report","case":"file","type":"pdf"},{"name":"Summary Report","case":"summary_file","type":"pdf"},{"name":"Version History","case":"report_versions"}]}',
  selectInspection: '{"date-title":"Ready Date","select-centre":false,"date-range-minus":1,"date-range-plus":0,"show-open-order-filter":false,"table":[{"name":"Arrived","key":"arrived_consol_date","case":"name"},{"name":"Size Chart","type":"number","key":"size_chart_YN","case":"normal"},{"name":"Insp.","type":"number","key":"inspection_number","case":"normal"},{"name":"Product","key":"style_code","case":"normal"},{"name":"TOT. QTY.","type":"number","key":"order_quantity","case":"normal"},{"name":"AQL","key":"select_for_AQL","case":"check"},{"name":"Inspection Date","key":"inspection_date","case":"select_date"},{"name":"Supplier Name","key":"supplier_name","case":"name"},{"name":"PO Numbers","key":"client_pos","case":"normal","empty":true},{"name":"Action","case":"insp-action"}]}',
  aqlPlan: '{"date-range-plus":0,"table":[{"name":"AQL","key":"qcdata_select_for_AQL","case":"select_unselect","show":false},{"name":"Insp. Date","key":"inspection_date","case":"name","show":true},{"name":"Loc.","type":"number","key":"UDC_id","case":"normal","show":true},{"name":"Insp.","type":"number","key":"inspection_number","case":"normal","show":true},{"name":"Supplier Name","key":"supplier_name","case":"name","show":true},{"name":"Season","key":"season_code","case":"normal","show":true},{"name":"PO Numbers","key":"client_pos","case":"normal","show":true},{"name":"STYLE","key":"style_code","case":"normal","show":true},{"name":"Description","key":"style_name","case":"name","show":true},{"name":"Colour","key":"colour","case":"name","show":true},{"name":"PO QTY.","type":"number","key":"order_quantity","case":"normal","show":true},{"name":"Level","type":"number","key":"sample_level","case":"level","show":true},{"name":"INSP. Qty.","type":"number","key":"inspection_quantity","case":"normal","show":true},{"name":"SUB.","type":"number","key":"inspection_number","case":"normal","show":false},{"name":"AQL Maj<","type":"number","key":"allowed_defects_maj","case":"normal","show":true},{"name":"AQL Min<","type":"number","key":"allowed_defects_min","case":"normal","show":true},{"name":"Tot. Defects","type":"number","key":"count_defects","case":"normal","show":true},{"name":"Current Status","key":"status_EN","case":"normal","show":true},{"name":"Result","key":"result","case":"result","show":true},{"name":"Inspector Name","key":"Inspector","case":"normal","show":true},{"name":"Customer","key":"customer_name","case":"normal","show":false}],"select-centre":false,"show-color":true,"title":"AQL Inspection Plan","show-po-number":true,"date-range-minus":1,"show-petek-number":false,"style-code":"Style Code","show-inspection-type":false,"status-filter":"Status"}',
  aqlReport: '{"show-close-btn":false,"show-max-allowed":true,"info-fields":[{"title":"Product Code","field":"style_code"},{"title":"Product Name","field":"style_name"},{"title":"Colour","field":"colour"},{"title":"Supplier","field":"supplier_name"},{"title":"PO Number","field":"PO_Nos"},{"title":"Order Size","field":"Order_Size"},{"title":"Submission","field":"inspection_number"},{"title":"Inspection Level","field":"sample_level"},{"title":"Sample Qty.","field":"inspection_quantity"}],"show-result":true,"show-summary-inspection-report":true,"title":"AQL Inspection Report","major":"Major","critical":"Critical","show-centre":false,"show-inspection-date":false,"show-customer-name":false,"minor":"Minor","show-inspection-type":false,"show-inspection-id":true}',
  mainPage: {"redirect-urls":[]},
};